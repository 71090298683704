import { Sort } from '@angular/material/sort';
import { createAction, props } from '@ngrx/store';
import { PageQuery } from '../../shared/models/page-query';
import { DeviceMessagesResult } from '@paldesk/shared-lib/data-access/provisioning-service-generated';
import { TableSettings } from '@design-system/components/table-settings';
import { MessageFilterPayload } from '../../shared';

export namespace MessagesActions {
  export const LoadMessages = createAction('[TELEMATIC-DOCTOR] LOAD_MESSAGES');
  export const LoadMessagesSuccess = createAction(
    '[TELEMATIC-DOCTOR] LOAD_MESSAGES_SUCCESS',
    props<{ payload: DeviceMessagesResult }>(),
  );
  export const LoadMessagesError = createAction(
    '[TELEMATIC-DOCTOR] LOAD_MESSAGES_ERROR',
    props<{ payload: string }>(),
  );
  export const SaveQueryMessages = createAction(
    '[TELEMATIC-DOCTOR] SAVE_QUERY_MESSAGES',
    props<{ payload: PageQuery }>(),
  );
  export const SaveFilterQueryMessages = createAction(
    '[TELEMATIC-DOCTOR] SAVE_FILTER_QUERY_MESSAGES',
    props<{ payload: MessageFilterPayload }>(),
  );
  export const SaveSortMessages = createAction(
    '[TELEMATIC-DOCTOR] SAVE_SORT_MESSAGES',
    props<{ payload: Sort }>(),
  );

  export const LoadUserTableSettingsMessages = createAction(
    '[TELEMATIC-DOCTOR] LOAD_USER_TABLE_SETTINGS_MESSAGES',
    props<{ allColumns: TableSettings[]; settingsKey: string }>(),
  );

  export const LoadUserTableSettingsMessagesSuccess = createAction(
    '[TELEMATIC-DOCTOR] LOAD_USER_TABLE_SETTINGS_MESSAGES_SUCCESS',
    props<{ payload: TableSettings[] }>(),
  );
}
