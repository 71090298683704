import { createSelector } from '@ngrx/store';
import { TelematicDoctorState } from '..';

export namespace MessagesSelectors {
  export const messagesState = (state: TelematicDoctorState) => state.messages;

  export const messages = createSelector(
    messagesState,
    (state) => state.messages,
  );
  export const messagesLoading = createSelector(
    messagesState,
    (state) => state.isMessagesRequestLoading,
  );
  export const pageQuery = createSelector(
    messagesState,
    (state) => state.query,
  );
  export const filterQuery = createSelector(
    messagesState,
    (state) => state.filterQuery,
  );
  export const sort = createSelector(messagesState, (state) => state.sort);
  export const rowCount = createSelector(
    messagesState,
    (state) => state.rowCount,
  );
  export const error = createSelector(messagesState, (state) => state.error);

  export const getUserSettings = createSelector(
    messagesState,
    (state) => state.userSettings,
  );
  export const getIsUserSettingsLoading = createSelector(
    messagesState,
    (state) => state.isUserSettingsLoading,
  );
}
